import {
  membersAppDefId,
  myOrdersInstallDefinition,
  ecomAppDefID,
  addressesAppDefId,
  myAdressesInstallDefinition,
  myWalletAppDefId,
  myWalletInstallDefinition,
  orderHistoryPageId,
} from '../constants';
import {Logger} from '@wix/bi-logger-ec-sf';

export class DependantApps {
  constructor(
    private readonly sdk: IEditorSdk,
    private readonly appToken: string,
    private readonly biLogger: Logger,
    private readonly options
  ) {
    //
  }

  public installApps(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.addCheckout();
      if (this.options && this.options.firstInstall) {
        if (this.options.origin && this.options.origin.type === 'ADI') {
          return resolve();
        } else {
          return this.installMembers().then(resolve, reject);
        }
      } else {
        const membersInstalled = await this.isMembersInstalled();
        if (membersInstalled) {
          //tslint:disable-next-line:no-floating-promises
          this.biLogger.exposureEventForTests({
            type: 'members_is_installed',
            testName: 'express_checkout',
            is_eligible: true,
          });
        } else {
          //tslint:disable-next-line:no-floating-promises
          this.biLogger.exposureEventForTests({
            testName: 'express_checkout',
            is_eligible: false,
          });
        }

        if (membersInstalled) {
          await this.installMembersAreaPages({shouldInstallMyOrders: false});
        }
        resolve();
      }
    });
  }

  public onMembersInstall(): Promise<any> {
    if (this.options.firstInstall) {
      //tslint:disable-next-line:no-floating-promises
      this.biLogger.exposureEventForTests({
        type: 'members_added_by_stores',
        testName: 'express_checkout',
        is_eligible: true,
      });
    } else {
      //tslint:disable-next-line:no-floating-promises
      this.biLogger.exposureEventForTests({
        type: 'members_added_by_user',
        testName: 'express_checkout',
        is_eligible: true,
      });
    }

    return Promise.all([this.installMembersAreaPages(), this.addCheckout()]);
  }

  private getMembersApi() {
    return this.sdk.application.getPublicAPI(this.appToken, {appDefinitionId: membersAppDefId});
  }

  private async isMembersInstalled(): Promise<boolean> {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: orderHistoryPageId});
  }

  private addCheckout() {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: 'checkout'}).then(isInstalled => {
      if (!isInstalled) {
        return this.sdk.tpa.add.component(this.appToken, {
          appDefinitionId: ecomAppDefID,
          componentType: 'PAGE',
          page: {
            pageId: 'checkout',
            shouldNavigate: false,
          },
        });
      }
    });
  }

  private installMembers() {
    return this.sdk.application.install(this.appToken, {
      appDefinitionId: membersAppDefId,
      initiatorAppDefinitionId: ecomAppDefID,
    });
  }

  private async installMembersAreaPages({shouldInstallMyOrders = true} = {}) {
    const [isMyAdressesInstalled, isMyWalletInstalled] = await Promise.all([
      this.sdk.tpa.isApplicationInstalled(this.appToken, {appDefinitionId: addressesAppDefId}),
      this.sdk.tpa.isApplicationInstalled(this.appToken, {appDefinitionId: myWalletAppDefId}),
    ]);

    const shouldInstallAdresses = !isMyAdressesInstalled;
    const shouldInstallMyWallet = !isMyWalletInstalled;

    const appsToInstall = [
      shouldInstallMyOrders && myOrdersInstallDefinition,
      shouldInstallAdresses && myAdressesInstallDefinition,
      shouldInstallMyWallet && myWalletInstallDefinition,
    ].filter(app => !!app);

    if (appsToInstall.length === 0) {
      return;
    }

    await this.getMembersApi().addApplications(appsToInstall);
    await this.sdk.document.save();
  }
}
